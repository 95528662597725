<template>
  <!--begin::Card-->
  <div class="card">
    <div
      class="card-header align-items-center justify-content-between py-5 gap-5"
    >
      <a
        class="btn btn-icon btn-light"
        title="Load new emails"
        @click="fetchEmails(null)"
        ><i class="bi fs-4" :class="getRefreshIconClass"></i
      ></a>
      <!--begin::Pagination-->
      <div class="d-flex align-items-center flex-wrap gap-2">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative">
          <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
          <span class="svg-icon svg-icon-2 position-absolute ms-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height="2"
                rx="1"
                transform="rotate(45 17.0365 15.1223)"
                fill="black"
              />
              <path
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="black"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
          <input
            type="text"
            data-kt-inbox-listing-filter="search"
            class="form-control form-control-solid mw-100 min-w-300px ps-12"
            placeholder="Search..."
            v-model="searchQuery"
            @change="onSearchEmail"
          />
        </div>
        <!--end::Search-->
      </div>
      <!--end::Pagination-->
    </div>
    <!--begin::Email list-->
    <div class="card-body p-0">
      <!--begin::Email list item-->
      <router-link
        v-for="message in messages"
        :key="message.id"
        :to="`/emails/${message.threadId}`"
        class="email-item d-flex flex-column text-dark"
        :class="{ read: isRead(message) }"
      >
        <div class="d-flex flex-row align-items-center row px-10 py-6">
          <!--begin::Author-->
          <div class="mail-author col-3 d-flex flex-row align-items-center">
            <span class="badge rounded-pill rounded-circle p-1 me-2" :class="{ 'bg-info': !isRead(message), 'bg-transparent': isRead(message) }"><span class="visually-hidden">New</span></span>
            <!--begin::Avatar-->
            <div class="symbol symbol-35px me-3">
              <div
                class="symbol-label"
                :class="{
                  'bg-light-info': isRead(message),
                  'bg-info': !isRead(message),
                }"
              >
                <span
                  :class="{
                    'text-info': isRead(message),
                    'text-white': !isRead(message),
                  }"
                >
                  {{ toInitials(message.from) }}
                </span>
              </div>
            </div>
            <!--end::Avatar-->
            <p class="mb-0 fw-bold">
              {{
                message.from.substring(0, message.from.indexOf("&lt;")) ||
                message.from
              }}
            </p>
          </div>
          <!--end::Author-->
          <!--begin::Mail content-->
          <div class="mail-content col-7">
            <p class="mb-0 fw-bolder">{{ message.subject }}</p>
            <p class="mb-0 text-muted" v-html="message.snippet"></p>
          </div>
          <!--end::Mail content-->
          <!--begin::Info-->
          <div
            class="
              mail-info
              col-2
              d-flex
              flex-row
              justify-content-end
              text-end
              align-items-center
            "
          >
            <p class="mb-0 fw-bolder">
              {{ timeAgo.format(new Date(message.date)) }}
            </p>
          </div>
          <!--end::Info-->
        </div>
        <div class="separator"></div>
      </router-link>
      <!--end::Email list item-->
    </div>
    <!--end::Email list-->

    <a
      v-if="messagesLoading"
      @click.prevent="nextPage()"
      href="#"
      class="btn btn-primary disabled"
      disabled
    >
      <span class="spinner-border spinner-border-sm align-middle ms-2" />
      Loading...
    </a>
    <a
      v-else-if="nextPageToken"
      @click.prevent="nextPage()"
      href="#"
      class="btn btn-primary"
    >
      Show more...
    </a>
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/ApiService";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
try {
  TimeAgo.addDefaultLocale(en);
} catch (e) {
  console.log("timeago");
}
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
  name: "Inbox",
  data() {
    return {
      nextPageToken: "",
      messagesLoading: true,
      messages: [],
      timeAgo: null,
      searchQuery: "",
    };
  },
  mounted() {
    this.timeAgo = new TimeAgo("en-US");
    setCurrentPageBreadcrumbs("Emails", []);
    this.fetchEmails();
  },
  methods: {
    onSearchEmail() {
      this.fetchEmails();
    },
    toInitials(str) {
      try {
        return str
          .match(/\b(\w)/g)
          .join("")
          .slice(0, 2)
          .toUpperCase();
      } catch (err) {
        return str.substring(0, 2).toUpperCase();
      }
    },
    nextPage() {
      console.log("nextPage");
      this.messagesLoading = true;
      this.fetchEmails(this.nextPageToken);
    },
    fetchEmails(pageToken) {
      this.messagesLoading = true;

      const params = {
        maxResults: 10,
        // q: `${this.$route.query.type === 'sent' ? 'label:SENT' : ''} subject:"NEORIS" ${this.searchQuery}`,
        q: `${this.$route.query.type === 'sent' ? 'label:SENT' : ''} ${this.searchQuery}`,
        labelIds: this.$route.query.type === 'sent' ? ['SENT'] : ['INBOX'],
        pageToken,
      };

      // ApiService.query(`gmail/${this.$route.query.type === 'sent' ? 'messages' : 'threads'}`, { params })
      ApiService.query('gmail/messages', { params })
        .then(({ data: { nextPageToken, parsedMessages } }) => {
          if (pageToken) {
            this.messages.push(...parsedMessages);
          } else {
            this.messages = parsedMessages;
          }
          this.nextPageToken = nextPageToken;
          this.messagesLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.messagesLoading = false;
        });
    },
    isRead(message) {
      return !message.labels.includes("UNREAD");
    },
  },
  computed: {
    getRefreshIconClass() {
      return {
        "bi-arrow-clockwise": !this.messagesLoading,
        "spinner-border spinner-border-sm align-middle": this.messagesLoading,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-link.active {
  background-color: lighten(#dddee4, 4%) !important;
}
.menu-link:hover {
  background-color: lighten(#dddee4, 9%) !important;
}
.email-item:hover {
  background-color: rgba(221, 222, 228, 0.1);
}
.read {
  background-color: rgba(221, 222, 228, 0.18);
}
</style>